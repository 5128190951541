import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import routes from '../../routes';

const styles = makeStyles((theme) => ({
  tapRoot: {
    width: 200,
    padding: 20,
    marginBottom: 25,
    fontSize: '1.200rem',
    color: '#fff',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  tapRootSingle: {
    width: 450,
    padding: 20,
    marginBottom: 25,
    fontSize: '1.200rem',
    color: '#fff',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

const ElectionTap = ({ electionData, type, webView }) => {
  const classes = styles();
  const history = useHistory();

  const getColor = (electionTapType) => {
    if (electionTapType.includes('Toss')) return '#acacac';
    if (electionTapType.includes('Solid D')) return '#245c94';
    if (electionTapType.includes('Likely D')) return '#347cbc';
    if (electionTapType.includes('Lean D')) return '#74a4d4';
    if (electionTapType.includes('Lean R')) return '#f47484';
    if (electionTapType.includes('Likely R')) return '#ec3c4c';
    if (electionTapType.includes('Solid R')) return '#bc2c3c';
    return null;
  };

  const tap = electionData?.ratings.filter((r) => r?.label.includes(type));

  const single = tap?.length === 1;

  const electionTapClick = (eData, tData) => {
    if (tData?.total <= 0) return;
    if (webView) {
      history.push(routes.electionsInternalDrillDownWebView(eData?.name.toLowerCase().replace(' ', '-'), tData?.label.toLowerCase().replace(' ', '-')));
    } else {
      history.push(routes.electionsInternalDrillDown(eData?.name.toLowerCase().replace(' ', '-'), tData?.label.toLowerCase().replace(' ', '-')));
    }
  };

  return (
    <>
      {tap.map((tapData) => (
        <Box
          className={single ? classes.tapRootSingle : classes.tapRoot}
          style={{ backgroundColor: getColor(tapData?.label), cursor: tapData?.total > 0 ? 'pointer' : 'auto' }}
          onClick={() => electionTapClick(electionData, tapData)}
        >
          {tapData?.label}: {tapData?.total}
        </Box>
      ))}
    </>
  );
};

ElectionTap.propTypes = {
  electionData: PropTypes.shape().isRequired,
  type: PropTypes.string.isRequired,
  webView: PropTypes.bool,
};

export default ElectionTap;
