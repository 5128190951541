import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';

const ElectionsIcon = ({ color = '#ffffff', mobile = false }) => (
  <Container
    style={{
      display: 'inline-block',
      width: 30,
      padding: 0,
      margin: mobile ? '0px 15px 0px 0px' : 0,
      marginRight: 10,
    }}
  >
    <svg
      width={30}
      height={30}
      viewBox="0 0 50.000000 50.000000"
      preserveAspectRatio="xMidYMid meet"
    >

      <g
        transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
        fill={color}
        stroke="none"
      >
        <path d="M47 452 l-46 -47 41 -63 c38 -58 48 -67 129 -105 78 -38 85 -44 67
-52 -12 -6 -24 -16 -26 -24 -3 -10 3 -12 29 -6 l34 6 -32 -26 c-47 -38 -43
-55 12 -55 34 0 45 4 45 15 0 12 -7 14 -27 10 l-28 -6 27 17 c23 15 27 25 26
66 0 44 -3 51 -39 79 -21 17 -39 35 -39 41 0 10 13 9 138 -8 24 -4 42 -2 42 3
0 6 -13 28 -29 51 -25 33 -38 42 -81 51 -28 6 -65 11 -82 11 -22 0 -40 10 -64
36 -19 19 -34 40 -34 45 0 18 -17 8 -63 -39z"
        />
        <path d="M290 245 c9 -10 30 -15 71 -15 l59 0 0 -50 c0 -27 -4 -50 -10 -50 -5
0 -10 14 -10 30 0 40 -22 39 -54 -3 -32 -42 -32 -47 -1 -47 18 0 23 -4 19 -15
-5 -11 1 -15 21 -15 20 0 26 4 21 15 -3 8 -1 15 4 15 6 0 10 -9 10 -20 0 -19
-7 -20 -135 -20 l-135 0 0 79 c0 58 -4 82 -15 91 -13 11 -15 2 -15 -69 0 -74
-2 -81 -20 -81 -13 0 -22 -10 -28 -32 -5 -18 -7 -36 -5 -40 2 -5 101 -8 220
-8 l216 0 -7 31 c-3 17 -6 35 -6 40 0 5 -9 9 -20 9 -17 0 -19 8 -22 83 l-3 82
-84 3 c-75 3 -83 1 -71 -13z m80 -105 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3
6 8 10 11 10 2 0 4 -4 4 -10z"
        />
        <path d="M160 177 c0 -19 27 -53 36 -45 9 10 -12 58 -26 58 -5 0 -10 -6 -10 -13z" />
      </g>
    </svg>
  </Container>
);

ElectionsIcon.propTypes = {
  color: PropTypes.string,
  mobile: PropTypes.bool,
};

export default ElectionsIcon;
