import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { format as formatDate } from 'date-fns';
import { useHistory } from 'react-router-dom';
import routes from '../../routes';

const styles = makeStyles((theme) => ({
  contest: {
    display: 'flex',
    paddingBottom: 20,
    borderBottom: '1px solid #000000',
    marginTop: 20,
  },
  icon: {
    width: 59,
    height: 59,
  },
  row: {
    display: 'flex',
    paddingBottom: 15,
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  left: {
    display: 'flex',
  },
  right: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  info: {
    marginLeft: 10,
  },
  title: {
    font: 'normal normal bold 18px/24px Roboto',
    letterSpacing: 1.29,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  description: {
    marginTop: 5,
    font: 'normal normal normal 14px/19px Roboto',
    letterSpacing: 1,
    color: theme.palette.text.secondary,
  },
  signUpBox: {
    marginRight: 90,
    minWidth: 200,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 68,
    },
  },
  signUpStarts: {
    font: 'normal normal bold 18px/24px Roboto',
    letterSpacing: 1.29,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  signUpDate: {
    font: 'normal normal bold 18px/24px Roboto',
    letterSpacing: 1.29,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  viewButton: {
    fontSize: 18,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 67,
      marginTop: 10,
    },
  },
}));

const ContestItem = ({ contest, webView }) => {
  const history = useHistory();
  const classes = styles();

  const beginsDate = formatDate(new Date(`${contest?.play_begins_date} 00:00:00`), 'MMMM d, u');

  return (
    <Box className={classes.contest}>
      <Box className={classes.row}>
        <Box className={classes.left}>
          <img alt="" className={classes.icon} src={contest?.icon_url} />
          <Box className={classes.info}>
            <Typography className={classes.title}>{contest?.name}</Typography>
            <Typography className={classes.description}>
              {contest?.description}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.right}>
          <Box className={classes.signUpBox}>
            <Typography className={classes.signUpStarts}>Sign Up Starts:</Typography>
            <Typography className={classes.signUpDate}>
              {beginsDate}
            </Typography>
          </Box>
          <Box className={classes.buttonBox}>
            <Button
              variant="contained"
              color="primary"
              className={classes.viewButton}
              // eslint-disable-next-line no-underscore-dangle, max-len
              onClick={() => history.push(webView ? routes.contestsDetailWebView(contest?._id) : routes.contestsDetail(contest?._id))}
            >
              {contest?.entering ? 'Go' : 'View'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

ContestItem.propTypes = {
  contest: PropTypes.shape().isRequired,
  webView: PropTypes.bool,
};

export default ContestItem;
