import { Box, Button, Container, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import ViewListIcon from '@material-ui/icons/ViewList';
import ElectionBar from './ElectionBar';
import { elections, getPercentages } from '../../actions/elections';
import ElectionTopBar from './ElectionTopBar';
import routes from '../../routes';

const styles = makeStyles((theme) => ({
  containerRoot: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    paddingTop: 21,
    paddingBottom: 21,
  },
  title: {
    fontSize: '1.725rem',
    marginTop: 10,
  },
  lastChange: {
    fontSize: '0.9rem',
    marginBottom: 10,
  },
  button: {
    display: 'block',
    width: 530,
    padding: 15,
    margin: '0px auto',
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  rightButtons: {
    width: 530,
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0px auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  legendTitle: {
    marginLeft: 5,
  },
}));

const ElectionList = ({ webView }) => {
  const classes = styles();
  const history = useHistory();
  const { data: electionData } = useQuery('elections', elections);

  const goToInternal = (slug) => {
    if (webView) history.push(routes.electionsInternalWebView(slug));
    else history.push(routes.electionsInternal(slug));
  };

  return (
    <Container classes={{ root: classes.containerRoot }}>
      <ElectionTopBar title="Election Ratings" />
      <Box className={classes.rightButtons}>
        <Button
          onClick={() => (webView ? history.push(routes.electionsLegendWebView)
            : history.push(routes.electionsLegend))}
        >
          <ViewListIcon />
          <Typography className={classes.legendTitle}>Legend</Typography>
        </Button>
      </Box>
      {electionData && Object.entries(electionData).map((election) => (
        <Box>
          <Button className={classes.button} onClick={() => goToInternal(election[1]?.name.toLowerCase().replace(' ', '-'))}>
            <Box className={classes.centerContent}>
              <Typography className={classes.title}>{election[1]?.name}</Typography>
              <Typography className={classes.lastChange}>
                Last Rating Change: {election[1]?.last_rating_changed_at}
              </Typography>
              <ElectionBar
                type={election[1]?.name}
                percentages={getPercentages(election[1])}
              />
            </Box>
          </Button>
        </Box>
      ))}
    </Container>
  );
};

ElectionList.propTypes = {
  webView: PropTypes.bool,
};

export default ElectionList;
