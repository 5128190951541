import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import MenuIcon from '@material-ui/icons/Menu';
import {
  AppBar,
  Box,
  ClickAwayListener,
  Collapse,
  Container,
  Divider,
  Hidden,
  IconButton,
  MenuItem,
  MenuList,
  Tabs,
} from '@material-ui/core';
import Avatar from '../Avatar';
import AvatarMenu from '../AvatarMenu';
import AvatarMenuItems from '../AvatarMenu/AvatarMenuItems';
import Link from '../../common/Link';
import NavLinkTab from '../NavLinkTab';
import SpotlightLogo from '../../common/SpotlightLogo';
import routes from '../../../routes';
import MyNewsIcon from '../../common/MyNewsIcon/MyNewsIcon';
import ContestIcon from '../../Promotions/ContestIcon';
import ExploreIcon from '../../common/ExploreIcon/ExploreIcon';
import MyLibraryIcon from '../../common/MyLibraryIcon/MyLibraryIcon';
import MyStatsIcon from '../../common/MyStatsIcon/MyStatsIcon';
import { useQuery } from '../../../hooks/useQuery';
import { getNotificationsCount } from '../../../actions/notifications';
import ElectionsIcon from '../../Elections/ElectionsIcon';

const styles = makeStyles((theme) => ({
  appBarRoot: {
    backgroundColor: theme.palette.background.default,
    boxShadow: '0 3px 6px rgba(0, 0, 0, .16)',
    overflowY: 'hidden',
  },
  navContainerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logo: {
    flex: '0 1 auto',
    marginRight: 42,
    verticalAlign: 'middle',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      marginRight: 20,
    },
  },
  tabsFlexContainer: {
    flex: '0 1 auto',
  },
  tabButtonRoot: {
    fontSize: '18px',
    lineHeight: '24px',
    padding: '18px 15px',
    '& > .MuiTab-wrapper': {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  menuIconMobileRoot: {
    color: theme.palette.text.primary,
    marginTop: 6,
    marginBottom: 6,
    marginLeft: 'auto',
  },
  menuBoxIconMobileRoot: {
    display: 'flex',
  },
  menuBoxIconDesktopRoot: {
    marginLeft: 'auto',
    display: 'flex',
  },
  menuIconDesktopRoot: {
    padding: 4,
    verticalAlign: 'middle',
  },
  itemRoot: {
    fontSize: 18,
    fontWeight: 500,
    paddingTop: 10,
    paddingBottom: 10,
  },
  itemGutters: {
    paddingLeft: 30,
    paddingRight: 30,
  },
  dividerRoot: {
    marginLeft: 12,
    marginRight: 12,
    marginBottom: 14,
    marginTop: 14,
  },
  link: {
    color: theme.palette.text.primary,
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const getTabIndex = (currentPathname) => {
  if (currentPathname.startsWith(routes.news.main)) {
    return 0;
  } if (currentPathname === routes.discover) {
    return 1;
  } if (currentPathname === routes.myLibrary) {
    return 2;
  } if (currentPathname === routes.myStats) {
    return 3;
  } if (currentPathname === routes.elections) {
    return 4;
  }
  return false;
};

const NavigationBar = ({ user, history }) => {
  const classes = styles();
  const darkMode = localStorage.getItem('darkMode') === 'true';
  const [avatarMenuAnchorEl, setAvatarMenuAnchorEl] = React.useState(null);
  const mobileMenuAnchorEl = React.useRef(null);
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);
  const selectedTabIndex = useMemo(() => (
    getTabIndex(history.location.pathname)
  ), [history.location.pathname]);

  const iconColorMyNews = darkMode ? (selectedTabIndex === 0 ? '#ffffff' : '#8c8b91') : (selectedTabIndex === 0 ? '#000000' : '#8c8b91');
  const iconColorExplore = darkMode ? (selectedTabIndex === 1 ? '#ffffff' : '#8c8b91') : (selectedTabIndex === 1 ? '#000000' : '#8c8b91');
  const iconColorMyLibrary = darkMode ? (selectedTabIndex === 2 ? '#ffffff' : '#8c8b91') : (selectedTabIndex === 2 ? '#000000' : '#8c8b91');
  const iconColorMyStats = darkMode ? (selectedTabIndex === 3 ? '#ffffff' : '#8c8b91') : (selectedTabIndex === 3 ? '#000000' : '#8c8b91');
  const iconColorElections = darkMode ? (selectedTabIndex === 4 ? '#ffffff' : '#8c8b91') : (selectedTabIndex === 4 ? '#000000' : '#8c8b91');

  const a11yProps = (index) => ({
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  });

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen((isOpen) => !isOpen);
  };

  const handleMobileMenuClose = (event) => {
    if ((mobileMenuAnchorEl.current && !mobileMenuAnchorEl.current.contains(event.target))) {
      setMobileMenuOpen(false);
    }
  };

  const handleAvatarMenuOpen = (event) => {
    setAvatarMenuAnchorEl(event.currentTarget);
  };

  const handleAvatarMenuClose = () => {
    setAvatarMenuAnchorEl(null);
  };

  const notificationsCountQuery = useQuery('notificationsCount', getNotificationsCount, {
    variables: [user?.profile?.id],
  });
  const notificationsCount = notificationsCountQuery.data;

  return (
    <>
      <AppBar
        position="sticky"
        classes={{ root: classes.appBarRoot }}
        ref={mobileMenuAnchorEl}
      >
        <Container classes={{ root: classes.navContainerRoot }}>
          <Link href={routes.news.main}>
            {/* Desktop Menu */}
            <Hidden mdDown>
              <SpotlightLogo
                className={classes.logo}
                header
                height={46}
              />
            </Hidden>

            {/* Mobile Menu */}
            <Hidden lgUp>
              <SpotlightLogo
                small={user?.profile?.current_contest}
                className={classes.logo}
                header
                height={46}
              />
            </Hidden>
          </Link>

          {/* Desktop Menu */}
          <Hidden mdDown>
            <Tabs
              value={selectedTabIndex}
              classes={{
                flexContainer: classes.flexContainer,
              }}
              indicatorColor="primary"
            >
              <NavLinkTab
                icon={<MyNewsIcon color={iconColorMyNews} />}
                exact
                to={routes.news.main}
                classes={{
                  root: classes.tabButtonRoot,
                }}
                disableRipple
                label="MyNews"
                {...a11yProps(0)}
              />
              <NavLinkTab
                icon={<ExploreIcon color={iconColorExplore} />}
                exact
                to={routes.discover}
                classes={{
                  root: classes.tabButtonRoot,
                }}
                disableRipple
                label="Search"
                {...a11yProps(1)}
              />
              <NavLinkTab
                icon={<MyLibraryIcon color={iconColorMyLibrary} />}
                exact
                to={routes.myLibrary}
                classes={{
                  root: classes.tabButtonRoot,
                }}
                disableRipple
                label="Your Library"
                {...a11yProps(2)}
              />
              <NavLinkTab
                icon={<MyStatsIcon color={iconColorMyStats} />}
                exact
                to={routes.myStats}
                classes={{
                  root: classes.tabButtonRoot,
                }}
                disableRipple
                label="MyStats"
                {...a11yProps(3)}
              />
              <NavLinkTab
                icon={<ElectionsIcon color={iconColorElections} />}
                exact
                to={routes.elections}
                classes={{
                  root: classes.tabButtonRoot,
                }}
                disableRipple
                label="Elections 2024"
                {...a11yProps(4)}
              />
            </Tabs>

            <Box className={classes.menuBoxIconDesktopRoot}>
              {user?.profile?.current_contest && (
                <ContestIcon user={user} />
              )}

              {/* Avatar Menu */}
              <IconButton
                classes={{ root: classes.menuIconDesktopRoot }}
                onClick={handleAvatarMenuOpen}
                ref={avatarMenuAnchorEl}
              >
                <Avatar user={user} notificationsCount={notificationsCount} />
              </IconButton>
              <AvatarMenu
                anchorEl={avatarMenuAnchorEl}
                open={Boolean(avatarMenuAnchorEl)}
                user={user}
                onClose={handleAvatarMenuClose}
                notificationsCount={notificationsCount}
              />
            </Box>
          </Hidden>

          {/* Mobile Menu */}
          <Hidden lgUp>
            <Box className={classes.menuBoxIconMobileRoot}>
              {user?.profile?.current_contest && (
                <ContestIcon mobile user={user} />
              )}
              <IconButton
                classes={{ root: classes.menuIconMobileRoot }}
                onClick={handleMobileMenuToggle}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Hidden>
        </Container>

        <Hidden lgUp>
          <div className="mobile-menu-items">
            <ClickAwayListener onClickAway={handleMobileMenuClose}>
              <Collapse in={mobileMenuOpen}>
                <MenuList>
                  <Link to={routes.news.main} classes={{ root: classes.link }}>
                    <MenuItem
                      classes={{ root: classes.itemRoot, gutters: classes.itemGutters }}
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <MyNewsIcon color={iconColorMyNews} mobile />
                      MyNews
                    </MenuItem>
                  </Link>
                  <Link to={routes.discover} classes={{ root: classes.link }}>
                    <MenuItem
                      classes={{ root: classes.itemRoot, gutters: classes.itemGutters }}
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <ExploreIcon color={iconColorExplore} mobile />
                      Search
                    </MenuItem>
                  </Link>
                  <Link to={routes.myLibrary} classes={{ root: classes.link }}>
                    <MenuItem
                      classes={{ root: classes.itemRoot, gutters: classes.itemGutters }}
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <MyLibraryIcon color={iconColorMyLibrary} mobile />
                      Your Library
                    </MenuItem>
                  </Link>
                  <Link to={routes.myStats} classes={{ root: classes.link }}>
                    <MenuItem
                      classes={{ root: classes.itemRoot, gutters: classes.itemGutters }}
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <MyStatsIcon color={iconColorMyStats} mobile />
                      MyStats
                    </MenuItem>
                  </Link>
                  <Link to={routes.elections} classes={{ root: classes.link }}>
                    <MenuItem
                      classes={{ root: classes.itemRoot, gutters: classes.itemGutters }}
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <ElectionsIcon color={iconColorElections} mobile />
                      Elections
                    </MenuItem>
                  </Link>
                  <Divider classes={{ root: classes.dividerRoot }} />
                  <AvatarMenuItems
                    user={user}
                    onClick={() => setMobileMenuOpen(false)}
                    notificationsCount={notificationsCount}
                  />
                </MenuList>
              </Collapse>
            </ClickAwayListener>
          </div>
        </Hidden>
      </AppBar>
    </>
  );
};

NavigationBar.propTypes = {
  user: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
};

export default withRouter(NavigationBar);
